import { Vector2D } from "./Vector2D";

interface Cavity {
  position: Vector2D;
  radius: number;
}

// Constants for easy tuning
const SHIP_MASS = 1;
const SHIP_DRAG_COEFFICIENT = 0.99;
const COLLISION_DAMPING = 0.5;
export const SHIP_RADIUS = 10;
export const ARM_RADIUS = 5;
const MAX_ARM_LENGTH = 100;
const ARM_SENSITIVITY = 0.5;
const PUSH_FORCE_FACTOR = 0.5; // Adjust this to change the strength of the push force

// Constants for impulse
const IMPULSE_STRENGTH = 150;
const IMPULSE_COOLDOWN = 500;

const EPSILON = 0.001; // Small value to prevent floating-point errors

// Add these constants at the top of the file
export const ARM_SEGMENT_LENGTH = 50; // Half of MAX_ARM_LENGTH
export const ARM_SEGMENT_WIDTH = 6;
export const ARM_JOINT_RADIUS = 4;

// Add this new function to calculate arm segments
export function calculateArmSegments(
  shipPosition: Vector2D,
  armPosition: Vector2D
) {
  const armVector = armPosition.subtract(shipPosition);
  const armLength = armVector.magnitude();
  const armAngle = Math.atan2(armVector.y, armVector.x);

  let segment1: { start: Vector2D; end: Vector2D; angle: number };
  let segment2: { start: Vector2D; end: Vector2D; angle: number };
  let joint: Vector2D;

  if (armLength <= EPSILON) {
    // Arm is at the ship's center, set default positions
    const defaultEnd = shipPosition.add(new Vector2D(ARM_SEGMENT_LENGTH, 0));
    segment1 = { start: shipPosition, end: defaultEnd, angle: 0 };
    segment2 = { start: defaultEnd, end: armPosition, angle: 0 };
    joint = defaultEnd;
  } else if (armLength >= MAX_ARM_LENGTH - EPSILON) {
    // Arm is fully extended
    const segmentVector = armVector.normalize().multiply(ARM_SEGMENT_LENGTH);
    const segmentEnd = shipPosition.add(segmentVector);
    segment1 = { start: shipPosition, end: segmentEnd, angle: armAngle };
    segment2 = { start: segmentEnd, end: armPosition, angle: armAngle };
    joint = segmentEnd;
  } else {
    // Arm is partially bent
    const halfAngle = Math.acos(armLength / (2 * ARM_SEGMENT_LENGTH));
    const segment1Vector = Vector2D.fromAngle(armAngle - halfAngle).multiply(
      ARM_SEGMENT_LENGTH
    );
    const segment2Vector = Vector2D.fromAngle(armAngle + halfAngle).multiply(
      ARM_SEGMENT_LENGTH
    );
    const segmentEnd = shipPosition.add(segment1Vector);

    segment1 = {
      start: shipPosition,
      end: segmentEnd,
      angle: armAngle - halfAngle,
    };
    segment2 = {
      start: segmentEnd,
      end: armPosition,
      angle: armAngle + halfAngle,
    };
    joint = segmentEnd;
  }

  return { segment1, segment2, joint };
}

export class Ship {
  position: Vector2D;
  velocity: Vector2D;
  mass: number;
  armPosition: Vector2D;
  lastImpulseTime: number;

  constructor(position: Vector2D) {
    this.position = position;
    this.velocity = new Vector2D(0, 0);
    this.mass = SHIP_MASS;
    this.armPosition = position.clone();
    this.lastImpulseTime = 0;
  }

  update(
    deltaTime: number,
    cavities: Cavity[],
    spacePressed: boolean,
    cursorPosition: Vector2D,
    cameraCenter: Vector2D
  ) {
    // Update arm position and apply push force
    this.updateArm(cursorPosition, cameraCenter, cavities);

    // Apply impulse if space is pressed
    if (spacePressed) {
      this.applyImpulse();
    }

    // Apply drag
    this.velocity = this.velocity.multiply(SHIP_DRAG_COEFFICIENT);

    // Update position
    this.position = this.position.add(this.velocity.multiply(deltaTime));

    // Handle collisions with cavities
    if (cavities.length > 0) {
      this.handleCavityCollisions(cavities);
    }
  }

  private updateArm(
    cursorPosition: Vector2D,
    cameraCenter: Vector2D,
    cavities: Cavity[]
  ) {
    const handlePosition = this.calculateHandlePosition(
      cursorPosition,
      cameraCenter
    );

    if (this.isPointInsideCavity(handlePosition, cavities)) {
      this.armPosition = handlePosition;
    } else {
      const intersection = this.findNearestWallIntersection(
        this.position,
        handlePosition,
        cavities
      );
      if (intersection) {
        this.armPosition = intersection;
      } else {
        // Fallback: keep the arm at its current position
        this.armPosition = this.position;
      }
    }

    // Calculate and apply force
    const forceMagnitude =
      this.armPosition.subtract(handlePosition).magnitude() * PUSH_FORCE_FACTOR;
    const forceDirection = this.position.subtract(this.armPosition).normalize();
    const force = forceDirection.multiply(forceMagnitude);
    this.applyForce(force);
  }

  private calculateHandlePosition(
    cursorPosition: Vector2D,
    cameraCenter: Vector2D
  ): Vector2D {
    const toCursor = cursorPosition.subtract(cameraCenter);
    const angle = Math.atan2(toCursor.y, toCursor.x);

    // Apply sensitivity to the distance
    const distance = Math.min(
      toCursor.magnitude() * ARM_SENSITIVITY,
      MAX_ARM_LENGTH
    );

    const armOffset = new Vector2D(
      Math.cos(angle) * distance,
      Math.sin(angle) * distance
    );

    return this.position.add(armOffset);
  }

  private isPointInsideCavity(point: Vector2D, cavities: Cavity[]): boolean {
    for (const cavity of cavities) {
      if (point.subtract(cavity.position).magnitude() <= cavity.radius) {
        return true;
      }
    }
    return false;
  }

  private findNearestWallIntersection(
    start: Vector2D,
    end: Vector2D,
    cavities: Cavity[]
  ): Vector2D | null {
    let nearestIntersection: Vector2D | null = null;
    let minDistance = Infinity;

    for (const cavity of cavities) {
      const intersection = this.lineCircleIntersection(
        start,
        end,
        cavity.position,
        cavity.radius
      );
      if (intersection) {
        const distance = start.subtract(intersection).magnitude();
        if (distance < minDistance) {
          minDistance = distance;
          nearestIntersection = intersection;
        }
      }
    }

    return nearestIntersection;
  }

  private lineCircleIntersection(
    lineStart: Vector2D,
    lineEnd: Vector2D,
    circleCenter: Vector2D,
    circleRadius: number
  ): Vector2D | null {
    const d = lineEnd.subtract(lineStart);
    const f = lineStart.subtract(circleCenter);

    const a = d.dot(d);
    const b = 2 * f.dot(d);
    const c = f.dot(f) - circleRadius * circleRadius;

    let discriminant = b * b - 4 * a * c;
    if (discriminant < 0) {
      return null;
    }

    discriminant = Math.sqrt(discriminant);

    const t1 = (-b - discriminant) / (2 * a);
    const t2 = (-b + discriminant) / (2 * a);

    if (0 <= t1 && t1 <= 1) {
      return lineStart.add(d.multiply(t1));
    }

    if (0 <= t2 && t2 <= 1) {
      return lineStart.add(d.multiply(t2));
    }

    return null;
  }

  private applyForce(force: Vector2D) {
    this.velocity = this.velocity.add(force.divide(this.mass));
  }

  private applyImpulse() {
    const currentTime = Date.now();
    if (currentTime - this.lastImpulseTime < IMPULSE_COOLDOWN) {
      return; // Don't apply impulse if still in cooldown
    }

    const armDirection = this.armPosition.subtract(this.position).normalize();
    const impulseForce = armDirection.multiply(-IMPULSE_STRENGTH);
    this.velocity = this.velocity.add(impulseForce.divide(this.mass));
    this.lastImpulseTime = currentTime;
  }

  handleCavityCollisions(cavities: Cavity[]) {
    let insideAnyCavity = false;
    let nearestCavity: Cavity | null = null;
    let nearestDistance = Infinity;

    for (const cavity of cavities) {
      const distanceToCenter = this.position.distanceTo(cavity.position);
      const distanceToEdge = distanceToCenter - cavity.radius;

      if (distanceToEdge < nearestDistance) {
        nearestCavity = cavity;
        nearestDistance = distanceToEdge;
      }

      if (this.isInsideCavity(cavity)) {
        insideAnyCavity = true;
        this.handleSingleCavityCollision(cavity);
      }
    }

    if (!insideAnyCavity && nearestCavity) {
      this.moveInsideNearestCavity(nearestCavity);
    }
  }

  private isInsideCavity(cavity: Cavity): boolean {
    return (
      this.position.distanceTo(cavity.position) <= cavity.radius - SHIP_RADIUS
    );
  }

  private handleSingleCavityCollision(cavity: Cavity) {
    const distanceToCenter = this.position.distanceTo(cavity.position);
    const collisionDistance = cavity.radius - SHIP_RADIUS;

    if (distanceToCenter > collisionDistance - EPSILON) {
      const normal = this.position.subtract(cavity.position).normalize();
      const velocityAlongNormal = this.velocity.project(normal);

      if (velocityAlongNormal.dot(normal) > 0) {
        // Moving away from the cavity wall, no collision response needed
        return;
      }

      // Collision response with bounce
      const bounceVelocity = velocityAlongNormal
        .reflect(normal)
        .multiply(1 - COLLISION_DAMPING);
      this.velocity = this.velocity
        .subtract(velocityAlongNormal)
        .add(bounceVelocity);

      // Position correction
      const correction = normal.multiply(
        collisionDistance - distanceToCenter + EPSILON
      );
      this.position = this.position.add(correction);
    }
  }

  private moveInsideNearestCavity(cavity: Cavity) {
    const towardsCavity = cavity.position.subtract(this.position).normalize();
    const newPosition = cavity.position.subtract(
      towardsCavity.multiply(cavity.radius - SHIP_RADIUS - EPSILON)
    );
    this.position = newPosition;

    // Optional: Adjust velocity to prevent immediate collision after moving inside
    const normalVelocity = this.velocity.project(towardsCavity);
    if (normalVelocity.dot(towardsCavity) > 0) {
      this.velocity = this.velocity.subtract(normalVelocity);
    }
  }
}
