import { Vector2D } from "./Vector2D";

export class Camera {
  position: Vector2D;
  zoom: number;
  width: number;
  height: number;

  constructor(position: Vector2D, zoom: number, width: number, height: number) {
    this.position = position;
    this.zoom = zoom;
    this.width = width;
    this.height = height;
  }

  follow(target: Vector2D) {
    this.position = target.clone();
  }

  worldToScreen(worldPos: Vector2D): Vector2D {
    return new Vector2D(
      (worldPos.x - this.position.x) * this.zoom + this.width / 2,
      (worldPos.y - this.position.y) * this.zoom + this.height / 2
    );
  }

  applyTransform(ctx: CanvasRenderingContext2D) {
    ctx.translate(this.width / 2, this.height / 2);
    ctx.scale(this.zoom, this.zoom);
    ctx.translate(-this.position.x, -this.position.y);
  }

  setDimensions(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}
