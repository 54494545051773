export class Vector2D {
  constructor(public x: number, public y: number) {}

  add(v: Vector2D): Vector2D {
    return new Vector2D(this.x + v.x, this.y + v.y);
  }

  subtract(v: Vector2D): Vector2D {
    return new Vector2D(this.x - v.x, this.y - v.y);
  }

  multiply(n: number): Vector2D {
    return new Vector2D(this.x * n, this.y * n);
  }

  divide(n: number): Vector2D {
    return new Vector2D(this.x / n, this.y / n);
  }

  magnitude(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  normalize(): Vector2D {
    const mag = this.magnitude();
    return mag > 0 ? this.divide(mag) : new Vector2D(0, 0);
  }

  limit(max: number): Vector2D {
    const magSq = this.x * this.x + this.y * this.y;
    if (magSq > max * max) {
      return this.normalize().multiply(max);
    }
    return this;
  }

  rotate(angle: number): Vector2D {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    return new Vector2D(
      this.x * cos - this.y * sin,
      this.x * sin + this.y * cos
    );
  }

  angleBetween(v: Vector2D): number {
    return Math.atan2(v.y - this.y, v.x - this.x);
  }

  static fromAngle(angle: number): Vector2D {
    return new Vector2D(Math.cos(angle), Math.sin(angle));
  }

  dot(v: Vector2D): number {
    return this.x * v.x + this.y * v.y;
  }

  clone(): Vector2D {
    return new Vector2D(this.x, this.y);
  }

  distanceTo(v: Vector2D): number {
    const dx = this.x - v.x;
    const dy = this.y - v.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  reflect(normal: Vector2D): Vector2D {
    const dot = this.dot(normal);
    return this.subtract(normal.multiply(2 * dot));
  }

  project(onto: Vector2D): Vector2D {
    const normalized = onto.normalize();
    return normalized.multiply(this.dot(normalized));
  }
}
