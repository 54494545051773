import "./styles.css";
import PartySocket from "partysocket";
import { Vector2D } from "./Vector2D";
import {
  Ship,
  SHIP_RADIUS,
  ARM_RADIUS,
  ARM_SEGMENT_LENGTH,
  ARM_SEGMENT_WIDTH,
  ARM_JOINT_RADIUS,
  calculateArmSegments,
} from "./Ship";
import { Camera } from "./Camera";
import type { Cavity } from "./Cavity";

const PARTYKIT_HOST = "floccer.blindmansion.partykit.dev";

const canvas = document.getElementById("myCanvas") as HTMLCanvasElement;
const ctx = canvas.getContext("2d")!;

// Set canvas size immediately
canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

// Game objects
const ship = new Ship(new Vector2D(1000, 1000)); // Start at a specific position
const camera = new Camera(new Vector2D(0, 0), 1, canvas.width, canvas.height);

// Networking
const socket = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

// Game state
let lastUpdateTime = performance.now();
const players: Map<string, Ship> = new Map(); // Change players from an object to a Map
let cavities: Cavity[] = [];

// Input state
const keys: { [key: string]: boolean } = {};
let spacePressed = false;

// Flag to indicate if we've received the initial state
let initialStateReceived = false;

// Store the client's socket ID
let mySocketId: string | null = null;

socket.addEventListener("open", () => {
  mySocketId = socket.id;
});

socket.addEventListener("message", (event) => {
  const message = JSON.parse(event.data);
  if (message.type === "stateUpdate") {
    // Update players
    message.state.players.forEach((playerState: any) => {
      const id = playerState.id;
      if (id !== mySocketId) {
        // Only update other players
        if (!players.has(id)) {
          players.set(id, new Ship(new Vector2D(playerState.x, playerState.y)));
        }
        const player = players.get(id)!;
        player.position.x = playerState.x;
        player.position.y = playerState.y;
        player.velocity.x = playerState.vx;
        player.velocity.y = playerState.vy;
        player.armPosition.x = playerState.armX; // Update arm position
        player.armPosition.y = playerState.armY; // Update arm position
      }
    });

    // Remove players that are no longer in the game
    players.forEach((_, id) => {
      if (!message.state.players.some((p: any) => p.id === id)) {
        players.delete(id);
      }
    });

    // Update cavities
    cavities = message.state.cavities.map((cavityData: any) => ({
      position: new Vector2D(cavityData.position.x, cavityData.position.y),
      radius: cavityData.radius,
    }));

    initialStateReceived = true;
  }
});

let cursorPosition = new Vector2D(0, 0);

// Update the mouse event listener
canvas.addEventListener("pointermove", (event) => {
  const rect = canvas.getBoundingClientRect();
  cursorPosition = new Vector2D(
    event.clientX - rect.left,
    event.clientY - rect.top
  );
});

// Add these constants
const UPDATE_INTERVAL = 1000 / 30; // 30ms, which is 30 times per second
let lastServerUpdateTime = 0;

function update(deltaTime: number) {
  if (!initialStateReceived) return;

  ship.update(
    deltaTime,
    cavities,
    spacePressed,
    cursorPosition,
    new Vector2D(canvas.width / 2, canvas.height / 2)
  );

  // Update camera position to follow the ship
  camera.follow(ship.position);

  // Throttle server updates to 20fps
  const currentTime = performance.now();
  if (currentTime - lastServerUpdateTime >= UPDATE_INTERVAL) {
    // Send player state to server
    socket.send(
      JSON.stringify({
        type: "playerUpdate",
        x: ship.position.x,
        y: ship.position.y,
        vx: ship.velocity.x,
        vy: ship.velocity.y,
        armX: ship.armPosition.x,
        armY: ship.armPosition.y,
      })
    );
    lastServerUpdateTime = currentTime;
  }
}

function render() {
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  if (!initialStateReceived) {
    ctx.fillStyle = "white";
    ctx.font = "24px Arial";
    ctx.textAlign = "center";
    ctx.fillText("Connecting...", canvas.width / 2, canvas.height / 2);
    return;
  }

  // Apply camera transform
  ctx.save();
  camera.applyTransform(ctx);

  // Fill the entire visible area with light grey (outside cavities)
  const visibleArea = new Vector2D(
    canvas.width / camera.zoom,
    canvas.height / camera.zoom
  );
  ctx.fillStyle = "#E0E0E0";
  ctx.fillRect(
    camera.position.x - visibleArea.x / 2,
    camera.position.y - visibleArea.y / 2,
    visibleArea.x,
    visibleArea.y
  );

  // Draw cavities
  ctx.fillStyle = "#202020"; // Dark grey, almost black
  cavities.forEach((cavity) => {
    ctx.beginPath();
    ctx.arc(
      cavity.position.x,
      cavity.position.y,
      cavity.radius,
      0,
      Math.PI * 2
    );
    ctx.fill();
  });

  // Render player's ship
  renderShip(ctx, ship, "green");

  // Render other players' ships
  for (const [id, playerData] of players) {
    if (id !== socket.id) {
      renderShip(ctx, playerData, "red");
    }
  }

  ctx.restore();
}

function renderShip(ctx: CanvasRenderingContext2D, ship: Ship, color: string) {
  // Draw ship body
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.arc(ship.position.x, ship.position.y, SHIP_RADIUS, 0, Math.PI * 2);
  ctx.fill();

  // Calculate arm segments
  const { segment1, segment2, joint } = calculateArmSegments(
    ship.position,
    ship.armPosition
  );

  // Draw arm segments
  ctx.strokeStyle = color;
  ctx.lineWidth = ARM_SEGMENT_WIDTH;

  // Draw first arm segment
  ctx.save();
  ctx.translate(segment1.start.x, segment1.start.y);
  ctx.rotate(segment1.angle);
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(ARM_SEGMENT_LENGTH, 0);
  ctx.stroke();
  ctx.restore();

  // Draw second arm segment
  ctx.save();
  ctx.translate(segment2.start.x, segment2.start.y);
  ctx.rotate(segment2.angle);
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(ARM_SEGMENT_LENGTH, 0);
  ctx.stroke();
  ctx.restore();

  // Draw arm joint
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.arc(joint.x, joint.y, ARM_JOINT_RADIUS, 0, Math.PI * 2);
  ctx.fill();

  // Draw arm end
  ctx.beginPath();
  ctx.arc(ship.armPosition.x, ship.armPosition.y, ARM_RADIUS, 0, Math.PI * 2);
  ctx.fill();
}

function gameLoop(currentTime: number) {
  const deltaTime = (currentTime - lastUpdateTime) / 1000;
  lastUpdateTime = currentTime;

  update(deltaTime);
  render();

  requestAnimationFrame(gameLoop);
}

// Start the game loop immediately
gameLoop(performance.now());

// Input handling
window.addEventListener("keydown", (e) => {
  keys[e.key.toLowerCase()] = true;
  if (e.code === "Space") {
    spacePressed = true;
  }
});

window.addEventListener("keyup", (e) => {
  keys[e.key.toLowerCase()] = false;
  if (e.code === "Space") {
    spacePressed = false;
  }
});

// Handle window resizing
window.addEventListener("resize", () => {
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  camera.setDimensions(canvas.width, canvas.height);
});
